import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import styles from './styles/Dropdown.module.scss';

const openIcon = 'fa-chevron-up';
const closeIcon = 'fa-chevron-down';

const Dropdown = ({ baseUrl = '', className, items, label, menuClassName }) => {
  const [show, setShow] = useState(false);
  const toggleRef = useRef(null);

  const handleToggleClick = useCallback(() => {
    setShow((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleRef]);

  return (
    <div className={classnames('dropdown', styles.container)} ref={toggleRef}>
      <a
        className={classnames(className, styles.toggle, show && styles.show)}
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={handleToggleClick}
      >
        {label}
        <i
          className={`fa-solid ${show ? openIcon : closeIcon}`}
          aria-hidden="true"
        ></i>
      </a>
      <div
        className={classnames(
          'dropdown-menu',
          show && styles.show,
          styles.menu,
          menuClassName,
        )}
        aria-labelledby="dropdownMenuLink"
      >
        {items.map(({ label, link }) => (
          <Link key={label} className="dropdown-item" to={`${baseUrl}${link}`}>
            <div>{label}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
