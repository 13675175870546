import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import useSiteMetadata from '../hooks/useSiteMetadata';
import styles from './styles/Layout.module.scss';
import NavBar from './NavBar';
import Footer from './Footer';

const Layout = ({
  className,
  children,
  footerClassName,
  footerTitle,
  footerSubtitle,
  footerCtaButtonProps,
  navClassName,
  signUpUrl,
  appUrl = '',
  basePath,
  navLinks,
  mini,
  transparent,
  hideFooter,
  hideSidebarLinks,
}) => {
  const siteMetadata = useSiteMetadata();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <div
      className={classnames(
        styles.container,
        transparent && styles.transparent,
        open && styles.fixed,
        className,
      )}
    >
      <NavBar
        className={navClassName}
        appUrl={`${siteMetadata.APP_URL}${appUrl}`}
        open={open}
        onSidebarOpen={handleOpen}
        onSidebarClose={handleClose}
        signUpUrl={signUpUrl}
        basePath={basePath}
        links={navLinks}
        mini={mini}
        hideSidebarLinks={hideSidebarLinks}
      />
      {children}
      {!hideFooter && (
        <Footer
          className={footerClassName}
          appUrl={siteMetadata.APP_URL}
          title={footerTitle}
          subtitle={footerSubtitle}
          ctaButtonProps={footerCtaButtonProps}
          basePath={basePath}
        />
      )}
    </div>
  );
};

export default Layout;
