import React from 'react';
import classnames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styles from './styles/Footer.module.scss';
import { SOCIAL_MEDIA } from '../constants';
import { COMPARE_INSURANCE_LINK } from '../constants/routes';
import CurveDownSvg from '../images/layout/curve-down.svg';
import QSvg from '../images/q-only.svg';
import classNames from 'classnames';

const QUASHED_LINKS = [
  {
    name: 'Articles',
    link: '/blog',
  },
  {
    name: 'FAQs',
    link: '/faqs',
  },
  {
    name: 'Terms & Conditions',
    link: '/terms',
  },
  {
    name: 'About Us',
    link: '/about-us',
  },
];

const DISCOVER_LINKS = [
  {
    name: 'Market Scan',
    link: '/market-scan',
  },
  {
    name: 'Types of Insurance',
    link: '/insurance-types',
  },  
  {
    name: 'Comparing Insurance in NZ',
    link: COMPARE_INSURANCE_LINK,
  },
];

const contactInfo = (
  <>
    <a href="mailto:team@quashed.co.nz">team@quashed.co.nz</a>
    <p>
      Suite E, 18 Northcroft Street,
      <br /> Takapuna, Auckland 0622,
      <br />
      New Zealand
    </p>
  </>
);

const icons = SOCIAL_MEDIA.map(({ Icon, url }) => (
  <a
    className={styles.icon}
    key={url}
    href={url}
    rel="noopener noreferrer"
    target="_blank"
  >
    <Icon />
  </a>
));

const Certs = ({ className, images }) => (
  <div className={classNames(styles.certs, className)}>
    <Image
      className={styles.certImg}
      fixed={images.ssl.childImageSharp.fixed}
    />
    <Image
      className={styles.certImg}
      fixed={images.phew.childImageSharp.fixed}
    />
  </div>
);

const Footer = ({ className }) => {
  const year = new Date().getFullYear();

  const images = useStaticQuery(graphql`
    query {
      ssl: file(relativePath: { eq: "ssl.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      phew: file(relativePath: { eq: "phew.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className={classnames(styles.container, className)}>
      <CurveDownSvg className={classnames(styles.curve, 'curve')} />
      <div className={classnames(styles.content, 'container')}>
        <div className={classnames(styles.col, styles.titleCol)}>
          <QSvg />
          <div className={styles.subtitle}>All your insurance in one place</div>
          <Certs images={images} />
          <div className={styles.mobileOnly}>{contactInfo}</div>
        </div>

        <div className={styles.col}>
          <div className={styles.colTitle}>
            Discover <div className={styles.bar} />
          </div>
          <ul className={styles.links}>
            {DISCOVER_LINKS.map(({ name, link }) => (
              <li key={name}>
                <Link to={link}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.col}>
          <div className={styles.colTitle}>
            Quashed <div className={styles.bar} />
          </div>
          <ul className={styles.links}>
            {QUASHED_LINKS.map(({ name, link }) => (
              <li key={name}>
                <Link to={link}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={classnames(styles.col, styles.desktopOnly)}>
          <div className={styles.colTitle}>
            Contact Us <div className={styles.bar} />
          </div>
          {contactInfo}
          <div>
            <div className={styles.icons}>{icons}</div>
          </div>
        </div>
      </div>
      <div className={classnames(styles.bottom, 'container')}>
        <div className={styles.bottomWrap}>
          <div className={styles.copyright}>
            ©{year} Quashed. All rights reserved
          </div>
          <div className={styles.bottomLinks}>
            <Link to="/privacy">Privacy</Link>
            <a href="mailto:team@quashed.co.nz?subject=Complaint">Complaints</a>
          </div>
        </div>
        <div className={styles.bottomIcons}>
          <div className={classnames(styles.icons, styles.mobileOnly)}>
            {icons}
          </div>
          <Certs className={styles.mobileOnly} images={images} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
