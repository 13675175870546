import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import LinkButton from './LinkButton';
import useAuth0 from '../hooks/useAuth0';
import styles from './styles/AuthButtons.module.scss';

const defaultRender = (component) => component;

const AuthButtons = ({
  appUrl,
  signUpUrl,
  loginClassName,
  signUpClassName,
  render = defaultRender,
}) => {
  const { isLoggedIn } = useAuth0();

  // If logged in, only show My Dashboard button
  if (isLoggedIn) {
    const myDashboardBtn = (
      <LinkButton
        key="dashboard-btn"
        className={cx(styles.dbBtn, signUpClassName)}
        href={appUrl}
        background="#4187f5"
        track
      >
        My dashboard
      </LinkButton>
    );

    return render(myDashboardBtn);
  }

  const loginBtn = (
    <OutboundLink
      key="login-btn"
      className={loginClassName}
      href={appUrl}
      title="Login"
    >
      Login
    </OutboundLink>
  );

  const signUpBtn = (
    <LinkButton
      key="signup-btn"
      className={signUpClassName}
      href={signUpUrl}
      background="#4187f5"
    >
      Sign up
    </LinkButton>
  );

  return (
    <>
      {render(loginBtn)}
      {render(signUpBtn)}
    </>
  );
};

export default AuthButtons;
