import { useEffect, useState } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0Instance = new Auth0Client({
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
  cookieDomain: process.env.GATSBY_AUTH0_COOKIE_DOMAIN,
  authorizationParams: {
    redirect_uri: process.env.GATSBY_AUTH0_CALLBACK_URL,
  },
});

let isAuthenticated = false;

const useAuth0 = () => {
  const [isLoggedIn, setAuthenticated] = useState(isAuthenticated);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await auth0Instance.getTokenSilently();
        // If getTokenSilently does not throw error, that means user is logged in
        isAuthenticated = true;
        setAuthenticated(true);
      } catch {
        // Don't throw error when getTokenSilently fails
        setAuthenticated(false);
      }
    };

    if (!isAuthenticated) {
      checkAuth();
    }
  }, []);

  return {
    auth0: auth0Instance,
    isLoggedIn,
  };
};

export default useAuth0;
