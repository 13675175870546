import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import AuthButtons from './AuthButtons';
import Dropdown from './Dropdown';
import Logo from './Logo';
import styles from './styles/SideBar.module.scss';
import CurveDown from '../images/layout/curve-down.svg';

const SideBar = ({
  appUrl,
  signUpUrl,
  show,
  onClose,
  pathname,
  basePath = '',
  links,
  mini,
  hideLinks,
}) => {
  return (
    <div
      className={classnames(
        styles.sidebar,
        show && styles.show,
        mini && styles.mini,
      )}
    >
      <div className={classnames('container', styles.logoContainer)}>
        <Link className={classnames('navbar-brand', styles.logo)} to={basePath}>
          <Logo />
        </Link>
        <div className={styles.burger} onClick={onClose} />
      </div>
      <div className={styles.wrap}>
        <div className={classnames('container', styles.top)}>
          <Link
            onClick={onClose}
            className={classnames(
              styles.navLink,
              pathname === '/' && styles.active,
              hideLinks && styles.hideLinks,
            )}
            to={basePath}
          >
            Home
          </Link>
          {links.map(({ link, title: linkTitle, type, getItems }) =>
            type === 'dropdown' ? (
              <div
                key={linkTitle}
                className={classnames(
                  styles.navLink,
                  hideLinks && styles.hideLinks,
                )}
              >
                <Dropdown
                  className={styles.dropdown}
                  menuClassName={styles.menu}
                  label={linkTitle}
                  items={getItems(basePath)}
                />
              </div>
            ) : link.includes('mailto') ? (
              <a
                key={linkTitle}
                className={classnames(
                  styles.navLink,
                  pathname.includes(link) && styles.active,
                  hideLinks && styles.hideLinks,
                )}
                href={link}
                title={linkTitle}
              >
                {linkTitle}
              </a>
            ) : (
              <Link
                key={linkTitle}
                className={classnames(
                  styles.navLink,
                  pathname.includes(link) && styles.active,
                  hideLinks && styles.hideLinks,
                )}
                onClick={onClose}
                title={linkTitle}
                to={`${basePath}/${link}`}
              >
                {linkTitle}
              </Link>
            ),
          )}
        </div>
        <div className={styles.bottom}>
          <CurveDown className={styles.curveDown} />
          <div
            className={classnames(
              'container',
              hideLinks && styles.noLinkBtnContainer,
            )}
          >
            <AuthButtons
              loginClassName={classnames(styles.navLink, styles.clearBtn)}
              signUpClassName={classnames(styles.navLinkWhite)}
              appUrl={appUrl}
              signUpUrl={signUpUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
