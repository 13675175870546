import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { getPathName } from '../common/utils';
import Dropdown from '../components/Dropdown';
import AuthButtons from '../components/AuthButtons';
import Logo from '../components/Logo';
import SideBar from '../components/SideBar';
import styles from './styles/NavBar.module.scss';

const DEFAULT_LINKS = [
  {
    link: '/insurance-types',
    title: 'Types of insurance',
  },
  {
    link: '/market-scan',
    title: 'Market Scan',
  },
  {
    link: '/about-us',
    title: 'About us',
  },
  {
    link: 'mailto:team@quashed.co.nz',
    title: 'Contact',
  },
];

const NavBar = ({
  appUrl,
  open,
  onSidebarOpen,
  onSidebarClose,
  signUpUrl = '/get-started',
  basePath = '',
  links = DEFAULT_LINKS,
  mini,
  className,
  hideSidebarLinks,
}) => {
  const pathname = getPathName();
  const signUpHref = signUpUrl.includes('mailto')
    ? signUpUrl
    : `${basePath}${signUpUrl}`;

  return (
    <nav
      className={classnames(
        styles.nav,
        mini && styles.mini,
        !mini && 'fixed-top',
        'navbar justify-content-between',
        className,
      )}
    >
      <div className="container">
        <Link
          className={classnames('navbar-brand', styles.navLink)}
          to={basePath}
        >
          <Logo />
        </Link>
        <div className={styles.icon}>
          <i className="fa-solid fa-bars fa-2xl"></i>
        </div>
        <ul
          className={classnames(
            'navbar-nav flex-row justify-content-end',
            styles.navItems,
          )}
        >
          {!mini &&
            links.map(
              ({
                link,
                className: linkClassName,
                title: linkTitle,
                type,
                getItems,
              }) => (
                <li key={linkTitle} className="nav-item flex-fill">
                  {type === 'dropdown' ? (
                    <Dropdown
                      className={classnames(
                        'nav-link',
                        styles.navLink,
                        linkClassName,
                        styles.dropdown,
                      )}
                      label={linkTitle}
                      items={getItems(basePath)}
                    />
                  ) : link.includes('mailto') ? (
                    <a
                      className={classnames(
                        'nav-link',
                        styles.navLink,
                        linkClassName,
                      )}
                      href={link}
                      title={linkTitle}
                    >
                      {linkTitle}
                    </a>
                  ) : (
                    <Link
                      className={classnames(
                        'nav-link',
                        styles.navLink,
                        linkClassName,
                        pathname.includes(link) && styles.active,
                      )}
                      to={`${basePath}/${link}`}
                      title={linkTitle}
                    >
                      {linkTitle}
                    </Link>
                  )}
                </li>
              ),
            )}

          <AuthButtons
            appUrl={appUrl}
            signUpUrl={signUpHref}
            render={(button) => (
              <li className="nav-item flex-fill">{button}</li>
            )}
            signUpClassName={classnames('nav-link', styles.navLinkBlue)}
            loginClassName={classnames(
              'nav-link',
              styles.navLink,
              styles.login,
            )}
          />
        </ul>
        <div
          className={classnames(styles.burger, open && styles.active)}
          onClick={open ? onSidebarClose : onSidebarOpen}
        />
      </div>
      <SideBar
        show={open}
        appUrl={appUrl}
        signUpUrl={signUpHref}
        onClose={onSidebarClose}
        pathname={pathname}
        basePath={basePath}
        links={links}
        mini={mini}
        hideLinks={hideSidebarLinks}
      />
    </nav>
  );
};

export default NavBar;
