export const ADVISER_ROUTE = '/adviser';
export const MARKET_SCAN_ROUTE = '/market-scan';
export const MARKET_SCAN_BROKER_ROUTE = '/market-scan/broker';
export const LIFE_MS_ROUTE = '/market-scan/new/Life';
export const PREMIUM_FLOW_ROUTE = '/premium';
export const GET_STARTED_LIFE_ROUTE = '/get-started/?source=Life';
export const GET_STARTED = '/get-started';
export const GET_STARTED_LIFE_MS_ROUTE = `/get-started/?redirectPath=${LIFE_MS_ROUTE}`;
export const GET_STARTED_MS_BROKER_ROUTE = `/get-started/?redirectPath=${MARKET_SCAN_BROKER_ROUTE}`;
export const GET_STARTED_PREMIUM_ROUTE = `/get-started/?premium=true&loginUrl=${PREMIUM_FLOW_ROUTE}`;
export const GET_STARTED_QUASHED_PLUS = `/quashed-plus/get-started`;
export const INSURANCE_TYPES_ROUTE = '/insurance-types';
export const COMPARE_INSURANCE_LINK = '/blog/compare-insurance-nz';

// Content pillars
export const ADVISER_CONTENT_PILLAR_URL = '/guides/insurance-adviser';
